/**
 * Created by osirvent on 08/04/2016.
 * Modified by aguerrero on 14/04/2016.
 */
angular
    .module('annexaApp')
    .controller('ThirdsController',['$rootScope', '$scope', '$state', 'HeaderService', 'Language', 'ThirdFactory', 'ErrorFactory', 'RouteStateFactory', 'TableFilterFactory', function ($rootScope, $scope, $state, HeaderService, Language, ThirdFactory, ErrorFactory, RouteStateFactory, TableFilterFactory) {
        $scope.languageColumn = Language.getActiveColumn();

        $scope.alerts = [];

        $scope.tfilter = ThirdFactory.getFilter($scope.languageColumn);
        var routeState = RouteStateFactory.getRouteState($state.current);
        if(routeState) {
            $scope.tfilter = routeState.state;
        }

        $scope.filterData = { thirdType: '', active: ''};
        var routeStateColumnsFilter = RouteStateFactory.getRouteState($state.current, 'ColumnsFilter');
        if(routeStateColumnsFilter) {
            $scope.filterData = routeStateColumnsFilter.state;
        }

        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.thirds') {
                $rootScope.headButtons = [new HeadButton('btn primary breadDivButton mb-xs-pt', undefined, '#dataTableInput','fa-plus', 'global.thirds.literals.newthird',undefined,'newThird').setPermissions('new_third')];
                $rootScope.subHeadButtons = [
					new HeadButtonSearch('#tableFilter'),
					new HeadButton('btn primary breadDivButton m-r-sm', undefined, '#dataTableInput',undefined, 'global.literals.mergeThirds', undefined, 'mergeThirds').setPermissions('merge_thirds') 
				];
            }
        });

        HeaderService.changeState($state.current);

        var getFilterCall = function(){
            var filterCall = TableFilterFactory.getFilterCall($scope.tfilter);
            if($scope.filterData.thirdType && $scope.filterData.thirdType != '') {
                filterCall.thirdType = $scope.filterData.thirdType;
            }
            return filterCall;
         }
        var getFilterCallAux = function(){
            var filterCallAux = TableFilterFactory.getFilterCallAux($scope.tfilter);
            filterCallAux.loggedUser = $rootScope.LoggedUser.id;
            if($scope.filterData.active && $scope.filterData.active != '') {
            	filterCallAux.active = $scope.filterData.active;
            }
            return filterCallAux;
        }

        var columnsAux = ThirdFactory.getListColumns($scope);
        var datatableSettings = $rootScope.app.configuration.datatables_settings;
        var columns = getDatatableColumnsSettings(datatableSettings, 'datatable_third', columnsAux);
        var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_third', columnsAux);
        $scope.tableOrderProperties = {sortName: 'identificationDocument', sort: [[1, 'asc']]};
        if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index > -1){
            $scope.tableOrderProperties = {sortName: orderProperties.name, sort: [[orderProperties.index,orderProperties.direction]]};
        }

        $scope.tableDefinition = {
            id: 'tableThirds',
            origin: 'thirds',
            objectType: 'Third',
            sortName: $scope.tableOrderProperties.sortName,
            sort: $scope.tableOrderProperties.sort,
            filterCall: getFilterCall(),
            filterCallAux: getFilterCallAux(),
            filterCallFunc: getFilterCall,
            filterCallAuxFunc: getFilterCallAux,
            columns: columns,
            containerScope: $scope
        };

        $scope.newThird = function () {
            $state.transitionTo('annexa.thirds.new');
        };

		$scope.mergeThirds = function () {
            ThirdFactory.mergeThirds(undefined, $scope.tableDefinition);
        }
        
		$scope.viewThird = function (id) {
            $state.transitionTo('annexa.thirds.edit', { "id" : id });
        }

        $scope.removeThird = function (id) {
            ThirdFactory.removeThird(id)
                .then(function (data) {
                    $scope.tableDefinition.reloadInternalData(true, true);
                }).catch(function (error) {
                    $scope.alerts.push({ msg: ErrorFactory.getErrorMessage('thirds', 'delete', error) })
            });
        }
        
        $scope.disableEnableThird = function (id, enable) {
            ThirdFactory.disableEnableThird(id, enable)
                .then(function (data) {
                    $scope.tableDefinition.reloadInternalData(true, true);
                }).catch(function (error) {
                    $scope.alerts.push({ msg: ErrorFactory.getErrorMessage('thirds', 'disable', error) })
            });
        }
    }])
    .controller('ThirdsNewController',['$rootScope', '$scope', '$state', 'HeaderService', 'AnnexaEntityFactory', '$timeout', function ($rootScope, $scope, $state, HeaderService, AnnexaEntityFactory, $timeout) {
        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.thirds.new') {
               $rootScope.subHeadButtons = [
                    new HeadButton('btn primary breadDivButton ml-sm-pt',undefined,'#new-third','fa-floppy-o','global.literals.save',undefined,'saveEntity'),
                    new HeadButton('btn grey-500 text-white breadDivButton ml-sm-pt', undefined, '#new-third',undefined,'global.literals.cancel',undefined,'cancelEntity')
                ];
            }
        });

        HeaderService.changeState($state.current);

        $scope.entity = AnnexaEntityFactory.getNewThirdForm();
    }])
    .controller('ThirdsEditController',['$rootScope', '$scope', '$state', '$filter', '$stateParams', 'HeaderService', 'BoxFactory', 'globalModals', 'AnnexaFormlyFactory', 'HelperService', 'AnnexaEntityFactory', 'ThirdFactory', 'Language', 'GlobalDataFactory', 'ErrorFactory', 'DialogsFactory', '$q',
        function ($rootScope, $scope, $state, $filter, $stateParams, HeaderService, BoxFactory, globalModals, AnnexaFormlyFactory,HelperService, AnnexaEntityFactory, ThirdFactory, Language, GlobalDataFactory, ErrorFactory, DialogsFactory, $q) {
    		$scope.third = ThirdFactory.Third;
    		HeaderService.onChangeState($scope, function (message) {
                if(message.state.name == 'annexa.thirds.edit') {
                    $rootScope.headButtons = [
                        new HeadButton('btn primary breadDivButton mb-xs-pt', undefined, '#edit-third','fa-plus', 'global.thirds.literals.newthird',undefined,'newThird').setPermissions('new_third')
                    ];
                    $rootScope.subHeadButtons = [
                        new HeadButton('btn danger text-white breadDivButton ml-sm-pt',undefined,'#edit-third','fa fa-remove','global.thirds.literals.removethird',undefined,'removeThird').setPermissions('delete_third'),
                        new HeadButton('btn primary breadDivButton ml-sm-pt', undefined, '#edit-third','fa fa-eye', 'global.documents.new.audit', undefined, 'auditThird').setPermissions('admin_audit'),
						new HeadButton('btn primary breadDivButton ml-sm-pt', undefined, '#edit-third',undefined, 'global.literals.mergeThirds', undefined, 'mergeThirds').setPermissions('merge_thirds')
                    ];
                    if($scope.third.active) {
                    	$rootScope.subHeadButtons.push(new HeadButton('btn warn text-white breadDivButton ml-sm-pt',undefined,'#edit-third','fa fa-remove','global.literals.disable',undefined,'disableThird').setPermissions('delete_third'));
                    }else if(!$scope.third.active) {
                    	$rootScope.subHeadButtons.push(new HeadButton('btn primary text-white breadDivButton ml-sm-pt',undefined,'#edit-third','fa fa-check','global.literals.enable',undefined,'enableThird').setPermissions('delete_third'));
                    }
                }
            });

            HeaderService.changeState($state.current);

            $scope.auditThird = function () {
                if ($scope.third && $scope.third.id) {
                    window.open($state.href('annexa.admin.audit_third', {"objectId": $scope.third.id}), '_blank');
                }
            };


            $scope.thirdModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.thirds'").toArray();
            $scope.docModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.doc.documents.all'").toArray();
            $scope.secModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.sec'").toArray();
            $scope.dossierModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.tram'").toArray();
            $scope.regInputModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.reg.input'").toArray();

            $scope.contactBox = AnnexaEntityFactory.getEditThirdBox('contact');
            $scope.consentBox = AnnexaEntityFactory.getEditThirdBox('consent');
            $scope.documentBox = AnnexaEntityFactory.getEditThirdBox('document');
            $scope.receiverBox = AnnexaEntityFactory.getEditThirdBox('receiver');
            $scope.dossierBox = AnnexaEntityFactory.getEditThirdBox('dossier');
            $scope.registerEntryBox = AnnexaEntityFactory.getEditThirdBox('registerEntry');
            
            var addTaskSave = function(newTask) {
                if(newTask){
                	var newTaskIds = $linq(newTask).select("x => x.id").toArray();
                	var tasksAux = angular.copy($scope.taskBox.content);
                	if(newTaskIds && newTaskIds.length > 0){
                		_.forEach(newTaskIds, function(id){
                			$scope.taskBox.content.pop();
                		});
                	}
                    ThirdFactory.createRelatedTask($scope.third.id, newTaskIds).then(function (data) {
                    	_.forEach(newTask, function(task){
                    		$scope.taskBox.content.push(task);
                    	});   
                    }).catch(function (error) {
                    	console.error(error);
                    });
                }
            }
            
            $scope.$on('annexaBoxTasksDelete', function (event, args) {
                if(args.origin && args.origin == 'third-task' ) {
                	ThirdFactory.deleteRelatedTask($scope.third.id, args.removedId).then(function (data) {
                    }).catch(function (error) {
                    	console.error(error);
                    });
                 }
            });
            
            $scope.taskBox = {
            	boxTitle: 'global.tasks.literals.relatedTasks',
            	permissions: { new: ['create_task'], view: ['create_task'] },
                search: { placeholder: 'global.literals.placeholderSearchTaskBox', advanced: true, saveSearch: addTaskSave },
                content: ThirdFactory.ThirdTasks,
                new: {},
                isEdit: true,
                config: {},
                origin: 'third-task'
            };
            
            $scope.receiverBox.boxDefinition.content = angular.copy(ThirdFactory.Third.representations);


            _.forEach(ThirdFactory.Third.consents, function(value) {
                delete value.thirdAddress.third;

                var consent = { id: value.id, consentType: value.consentType, thirdAddress: value.thirdAddress };

                if(value.familyProcedure) {
                    consent.title = value.familyProcedure[Language.getActiveColumn()];
                } else if (value.procedure) {
                    consent.title = value.procedure[Language.getActiveColumn()];
                } else if (value.dossier) {
                    consent.title = value.dossier.dossierNumber + " (" + value.dossier.subject + ")";
                } else {
                    consent.title = '';
                }

                var thirdAddress = new ThirdAddress(undefined,Language.getActiveColumn());
                consent.thirdAddress.name = thirdAddress.getAddressHtml(consent.thirdAddress, false, true);

                $scope.consentBox.boxDefinition.content.push(consent);
            });

            $scope.contactBox.boxDefinition.content = ThirdFactory.Third.addresses;
            $scope.contactBox.boxDefinition.deletedAddresses = ThirdFactory.ThirdAddressesDeleted;
            $scope.documentBox.boxDefinition.content = ThirdFactory.Third.documents;
            $scope.documentBox.boxDefinition.module = "THIRDS";
            $scope.dossierBox.boxDefinition.content = (ThirdFactory.relatedDossiers)?ThirdFactory.relatedDossiers:[];
            $scope.registerEntryBox.boxDefinition.content = (ThirdFactory.relatedRegisterEntries)?ThirdFactory.relatedRegisterEntries:[];
            $scope.registerEntryBox.boxDefinition.languageColumn = Language.getActiveColumn();
            
            $scope.removeThird = function () {
                ThirdFactory.removeThird($scope.third.id)
                    .then(function (data) {
                        $state.transitionTo('annexa.thirds');
                    }).catch(function (error) {
                    $scope.alerts.push({ msg: ErrorFactory.getErrorMessage('thirds', 'delete', error) });
                });
            }
            
            $scope.disableThird = function () {
            	$scope.disableEnableThird($scope.third.id, false);
            }
            
            $scope.enableThird = function () {
            	$scope.disableEnableThird($scope.third.id, true);
            }
            
            $scope.disableEnableThird = function (id, enable) {
                ThirdFactory.disableEnableThird(id, enable)
                    .then(function (data) {
                    	$state.reload();
                    }).catch(function (error) {
                        $scope.alerts.push({ msg: ErrorFactory.getErrorMessage('thirds', 'disable', error) })
                });
            }

            $scope.thirdTypes = [
                {"id": "PHISICAL", "name": $filter('translate')('global.thirds.literals.phisical')},
                {"id": "LEGAL", "name": $filter('translate')('global.thirds.literals.legal')},
                {"id": "PERSONWITHOUTLEGALPERSONALITY", "name": $filter('translate')('global.thirds.literals.personwithoutlegalpersonality')}
            ];

            $scope.identificationDocumentTypes = [];
            
            $scope.thirdIdentificationDocumentTypesLegal = [
        		{"id": "NIF", "name": "NIF"},
        		{"id": "OTHER", "name": "global.literals.other"}
        	];
            
            $scope.identificationDocumentTypesGeneric = [
                {"id": "DNI", "name": "DNI/NIF"},
                {"id": "NIE", "name": "NIE/NIF"},
                {"id": "NIF", "name": "NIF"}
            ];
            
            //En modo ESET, en caso de personas físicas debe mantenerse el literal Pasaporte, si no debe ser Otros
            if($rootScope.esetMode) {            	
            	$scope.identificationDocumentTypesGeneric.push({"id": "OTHER", "name": "global.thirds.literals.passport"});
            } else {
                $scope.identificationDocumentTypesGeneric.push({"id": "OTHER", "name": "global.literals.other"});
            }
            
            //Carga los datos del desplegable identificationDocumentType
            if ($scope.third.thirdType == "LEGAL" || $scope.third.thirdType == 'PERSONWITHOUTLEGALPERSONALITY') {
            	$scope.identificationDocumentTypes = $scope.thirdIdentificationDocumentTypesLegal;
            } else {
            	$scope.identificationDocumentTypes = $scope.identificationDocumentTypesGeneric;
            }

            $scope.cancel = function(){
                $state.transitionTo('annexa.thirds');
            }
			$scope.mergeThirds = function () {
	            ThirdFactory.mergeThirds($scope.third);
	        }
            $scope.openModalUpdateThird = function() {
                var modal = {
                    title: 'global.literals.basicInfo',
                    size: 'modal-md',
                    icon: '',
                    submitModal: function () {
                    },
                    alerts: []		
                }                
                
                var saveModalUpdateThird = function(data){
                	var thirdEdited = angular.copy($scope.third);
                	thirdEdited.thirdType = data.annexaFormly.model.row1.thirdType;
                	thirdEdited.identificationDocumentType = data.annexaFormly.model.row2.identificationDocumentType;
                	thirdEdited.identificationDocument = data.annexaFormly.model.row3.identificationDocument;
                	thirdEdited.identificationReference = data.annexaFormly.model.row4.identificationReference;
                	thirdEdited.observations = data.annexaFormly.model.row5.observations;
                	
                	$scope.updateThird(thirdEdited);
                	modal.close();
                }
                
                var fields = AnnexaEntityFactory.thirdFormFields('col-sm-12');
                
                modal.annexaFormly = new AnnexaFormly();                
                modal.annexaFormly.addGroup('row1', '', [fields.thirdType], 'annexaRow');                
                modal.annexaFormly.addGroup('row2', '', [fields.identificationDocumentType], 'annexaRow');
                modal.annexaFormly.addGroup('row3', '', [fields.identificationDocument], 'annexaRow');
                modal.annexaFormly.addGroup('row4', '', [fields.identificationReference], 'annexaRow');
                modal.annexaFormly.addGroup('row5', '', [fields.observations], 'annexaRow');
                
                modal.annexaFormly.model = {row1: {thirdType: $scope.third.thirdType}, 
                						    row2: {identificationDocumentType: $scope.third.identificationDocumentType, counter: 0}, 
                						    row3: {identificationDocument: $scope.third.identificationDocument}, 
                						    row4: {identificationReference: $scope.third.identificationReference},
                						    row5: {observations: $scope.third.observations}};
                
                fields.thirdType.templateOptions.onSelected($scope.third.thirdType);
                
                AnnexaFormlyFactory.showModalPromise("openModalUpdateThird", modal, saveModalUpdateThird, false, false, 'global.literals.save').then(function (modalAux) {
                    modal.modal = modalAux;
                    modal.close = modalAux.element.scope().close;
                    modalAux.element.modal();
                    angular.element(".modal-title").focus();
                }).catch(function () {
                    var a = 0;
                });
                
            }
            
            $scope.updateThird = function(thirdEdited) {
            	var deferrend = $q.defer();
            	                
                if (thirdEdited.identificationDocument != $scope.third.identificationDocument) {
                	ThirdFactory.Third = thirdEdited;
                	
	                ThirdFactory.existThirdByIdentificationDocument(thirdEdited.identificationDocument, thirdEdited.identificationReference).then(function(data2){
	                	if(data2.data && data2.data.length > 0 && !$linq(data2.data).contains(ThirdFactory.Third, "(x, y) => x.id == y.id")) {
	                		if ($rootScope.app.configuration.can_duplicate_third_by_document_number.value) {
	                			//Se pueden duplicar terceros por numero de documento
		                		DialogsFactory.confirm('global.literals.existThird', 'global.literals.existThirdConfirm').then(function (data3) {
		                			thirdFactoryUpdateThird(deferrend);
		                		}).catch(function (data) {
		                			deferrend.resolve(false);
		                		});                		
	                		} else {
	                			//No se pueden duplicar terceros por numero de documento
	                			DialogsFactory.notify('DIALOGS_EXIST_THIRD');
	                			deferrend.resolve(false);
	                		}
	                	} else {	                		
	                		thirdFactoryUpdateThird(deferrend);
	                	}
	                }).catch(function (error) {
	                	var msg = ErrorFactory.getErrorMessage('thirds', 'new', error);
	                	$scope.alerts.push({ msg: msg });
	                	deferrend.reject(msg);
	                });
                } else {
                	ThirdFactory.Third = thirdEdited;
                	thirdFactoryUpdateThird(deferrend);
                }
                
                return deferrend.promise;
            }
            
            var thirdFactoryUpdateThird = function(deferrend) {            	
            	ThirdFactory.updateThird(ThirdFactory.Third).then(function (data) {
            		if (ThirdFactory.Third.identificationDocumentType != data.identificationDocumentType) {
            			$scope.alerts.push({ msg: 'global.errors.thirdDocumentTypeCorrected', type: 'warning' });
            		}
            		if (ThirdFactory.Third.thirdType != data.thirdType) {
            			$scope.alerts.push({ msg: 'global.errors.thirdTypeCorrected', type: 'warning' });
            		}            		
            		$scope.third = data;
            		deferrend.resolve(true);
            	}).catch(function (error) {
            		var msg = $filter('translate')(ErrorFactory.getErrorMessage('thirds', 'update', error.data));
            		$scope.alerts.push({ msg: msg });
            		deferrend.reject(msg);
	            });            	
            }

            $scope.printThirdDocumentType = function(model){
                var documentType = '';
                if(model == "DNI"){
                    documentType = 'DNI/NIF';
                }else if(model == "NIE"){
                    documentType = 'NIE/NIF';
                }else if(model == "NIF") {
                    documentType = 'NIF';
                }else if(model == "OTHER") {
                	if($rootScope.esetMode) {                		
                		documentType = 'global.thirds.literals.passport';
                	} else {
                		documentType = 'global.literals.others';
                	}
                }
                return $filter('translate')(documentType);
            }

            $scope.printThirdType = function(model){
                var thirdType = '';
                if(model == "PHISICAL"){
                    thirdType = 'global.thirds.literals.phisical';
                }else if(model == "LEGAL"){
                    thirdType = 'global.thirds.literals.legal';
                }else if(model == "PERSONWITHOUTLEGALPERSONALITY") {
                    thirdType = 'global.thirds.literals.personwithoutlegalpersonality';
                }
                return $filter('translate')(thirdType);
            }

            $rootScope.showLoadingdivSignin = false;
        }]);